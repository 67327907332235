.btn {
    margin-left: 0;
    margin-right: 0.4rem;
    font-weight: bold;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #107eab;
    --bs-btn-border-color: #107eab;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0e688b;
    --bs-btn-hover-border-color: #0e688b;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #005379;
    --bs-btn-active-border-color: #005379;
    --bs-btn-border-radius: 0;
}

.btn-outline-primary {
    --bs-btn-color: #107eab;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #107eab;
    --bs-btn-hover-color: #0e688b;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #0e688b;
    --bs-btn-active-color: #005379;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #005379;
    --bs-btn-border-radius: 0;
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #df3611;
    --bs-btn-border-color: #df3611;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #a0270c;
    --bs-btn-hover-border-color: #a0270c;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #a0270c;
    --bs-btn-active-border-color: #a0270c;
    --bs-btn-border-radius: 0;
}

.btn-outline-danger {
    --bs-btn-color: #df3611;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #df3611;
    --bs-btn-hover-color: #a0270c;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #a0270c;
    --bs-btn-active-color: #a0270c;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #a0270c;
    --bs-btn-border-radius: 0;
}

.smaller-button {
    text-align: center;
    justify-content: center;
    font-size: 12px;
    color: white;
}

.smaller-button-position {
    display: flex;
    flex-direction:  row;
}

.horizontal-flex {
    display: flex;
    flex-direction:  row;
    padding-top: 0.1em;
    padding-right: 0.1em;
    padding-bottom: 0.1em;
}

.select-question {
    display: flex;
    flex-direction:  row;
    margin-left: 1em;
    text-align: center;
    padding-top: 0.8px;
    padding-bottom: 0.8px;
    padding-left: 5.6px;
    padding-right: 8.6px;
}

.select-question:hover {
    border: solid;
    border-width: thin;
    border-color: rgb(134, 190, 204);
    cursor: pointer;
    background-color: rgb(213, 230, 238);
    transition: 0.15;
    border-radius: 0.1em;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 4.8px;
    padding-right: 7.8px;
}





/* ConfirmationDialog*/
.ConfirmDialogBackground {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 100;
}

.confirmDialogBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: white;
    padding: 3em;
    border-radius: 0.25em;
    border-color: #107eab;
    border-style: solid;
    z-index: 200;
}

.confirmationDialogText {
    display: flex;
    font-size: 18px;
    font-weight: bold;
}

.confirmationDialogButtonAlignment {
    display: flex;
    align-items: center;
    margin-top: 4em;
}

.confirmationDialogButton {
    margin-left: 1.5em;
    margin-right: 1.5em;
}


/* Used currently on LinkSelector.js (4.11.2022) */
.faded {
    color: gray;
}


/* Used currently on feedback page (4.11.2022) */
a.nav-link:link, a.nav-link:visited, a.inText:link, a.inText:visited {
    color: #107eab;
}

a.nav-link:hover, a.nav-link:active, a.inText:hover, a.inText:active {
    color: #005379;
}


/*Buttons*/

/*Buttons with icon*/
.btn-icon > svg {
    vertical-align: middle;
    position: relative;
    top: -2px;
    color: #107EAB;
}

.btn-icon:hover > svg {
    color: #005379;
}

/*Remove button*/
.btn-remove > svg {
    vertical-align: middle;
    position: relative;
    top: -2px;
    color: red;
}

.btn-remove:hover > svg {
    color: #BD0016;
}

/*Radio button*/
.form-check-input:checked {
    background-color: #107EAB;
    border-color: #107EAB;
}

/* General list component */

.rdm-list th {
    font-size: 0.8rem !important;
}

.rdm-list-head-item {
    cursor: pointer !important;
}


/* React Bootstrap Tooltip color */
.tooltip > .tooltip-inner {
    border: 1px dashed #107eab;
    background-color: #fff;
    color: #000222;
}

.tooltip.show {
    opacity: 1;
}

.tooltip > .tooltip-arrow::before {
    border-right-color: #107eab !important;
}

/*Accordion*/

.accordion-button:not(.collapsed) {
    color: white;
    background-color: #107EAB;
}

.accordion-button:after {
    color: white;
}

.accordion-button:hover {
    background-color: #005379;
    color: white;
}
